<template>
  <b-container class="dashboard bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col cols="12" md="12" lg="8" style="margin:auto;text-align:center">

        <b-img :src="require('@/assets/img/error.png')"></b-img>
        <h1>{{ $t('common.404.title') }}</h1>
        <p class="lead">{{ $t('common.404.description') }}</p>
        <b-button class="" variant="primary" to="/dashboard">{{$t('common.404.back_to_dashboard')}}</b-button>
        <br><small style="color:#f7f6f6">{{env.VUE_APP_ID_STRING}}</small>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: '404',
  data () {
    return {
      env: process.env
    }
  }
}
</script>

<style scoped>

</style>
